@import "styles/vars.scss";

.main {
  background-color: $color-grey-10;
  height: 100%;
  flex-direction: column;
  position: sticky;
  display: flex;
  overflow: auto;
  padding: $size-m $size-xxl 0 $size-xxl;
}
.popupText {
  color: $color-grey-90;
  margin-bottom: $size-m;
  white-space: pre-line;
  text-align: left;
  max-width: 100%;
}
.text {
  font-size: 14px;
  width: 450px;
}
.content {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  justify-content: center;
  box-sizing: border-box;

  .container__content {
    flex: 1;
    overflow-y: auto;
  }

  flex-grow: 1;
}

.leftPanel {
  background: $color-white-primary;
  visibility: hidden;
  width: 0px;
  transition: width 0.4s, visibility 1s;
  z-index: 400;
  position: absolute;
  overflow-x: hidden;
  box-shadow: 0 $size-ml $size-ml $color-grey-50;
  left: 0;
  top: 0;
  height: 100%;
  border-left: 1px solid #f2f3f6;
}

.open {
  display: flex;
  flex-direction: column;
  visibility: visible;
  width: 300px;
  transition: visibility 0.1s;
}

.leftPanelHeader {
  /*...*/
  top: 0;
  width: 95%;
  text-align: end;
  height: 50px;
  transition: transform 0.3s;
  transform: translateY(-50px);
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.open .leftPanelHeader {
  transition: transform 0.3s;
  transform: translateY(0px);
}

.leftPanelContainer {
  /*...*/
  top: 0;
  transition: transform 0.4s, opacity 0.4s;
  opacity: 0;
  left: 0;
  transform: translate3d(-100%, 0, 0);
}

.open .leftPanelContainer {
  transform: translate3d(0, 0, 0);
  transition-delay: 0s;
  opacity: 1;
}

.leftPanelContainer hr {
  border-top: 1px solid $color-grey-10;
  margin-top: -5px;
}

.debug {
  padding: $size-ml $size-m;
  border-radius: $size-xs;
  background-color: $color-white-primary;
  box-shadow: 0 $size-ms $size-ms rgba($color-grey-90, 0.1);
}
