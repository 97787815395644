@import "../../styles/vars.scss";

.container {
  display: flex;
  align-items: center;
  color: $color-ui-blue-70;
  cursor: pointer;
  font-size: $line-height-xs;
  span {
    padding-left: $size-s;
  }
}

.primaryRed {
  color: $color-brand-red;
}
