@import "../../styles/vars.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  margin: $size-ms;
  padding: $size-s;
  width: 175px;
  height: 175px;

  .icon {
    padding: $size-ms;
    width: 88px;
    align-self: center;

    .imageIcon {
      width: 100%;
    }
  }

  .title {
    font-size: $font-size-m;
    font-weight: bold;
    text-align: center;
    color: $color-ui-app-launcher-black;
  }

  .description {
    margin-top: $size-xxs;
    font-size: $font-size-xs;
    text-align: center;
    height: $size-xxl;
    color: $color-ui-app-launcher-black;
  }
}