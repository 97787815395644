@import "../../styles/vars.scss";

.content {
  position: sticky;
  background-color: $color-app-background-light;
}

.sidebar {
  flex: 2;
  height: 100%;
  overflow-y: auto;
}

.dashboardContent {
  flex: 5;
  height: 100%;
  overflow-y: auto;
  flex-direction: column;
  display: flex;
}

.children {
  flex: 1;
}

.panel {
  /*...*/
  background: $color-brand-white;
  visibility: hidden;
  width: 0px;
  transition: width 0.5s, visibility 0.8s;
  z-index: 400;
  position: absolute;
  overflow-x: hidden;
  top: 0;
  box-shadow: 0px 20px 20px grey;
  height: 100%;
  border-left: 1px solid $color-grey-20;
}

.right {
  right: 0;
}

.openLeft {
  right: auto;
  left: 0;
}

.open {
  display: flex;
  flex-direction: column;
  visibility: visible;
  width: 300px;
}

.panelHeader {
  /*...*/
  top: 0;
  text-align: end;
  height: 50px;
  transition: transform 0.3s;
  transform: translateY(-50px);
}

.open .panelHeader {
  transition: transform 0.3s;
  transform: translateY(0px);
}

.panelContainer {
  /*...*/
  flex-grow: 1;
  top: 0;
  transition: transform 0.3s 0.3s;
  padding: 10px 30px;
}

.panelContainer {
  right: 0;
  transform: translate3d(100%, 0, 0);
}

.open .panelContainer {
  transform: translate3d(0, 0, 0);
  transition-delay: 0s;
}

.panelContainer hr {
  border-top: 1px solid $color-grey-20;
  margin-top: -5px;
}
