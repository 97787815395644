@import "styles/vars.scss";

.dataGridContainer {
  flex:1;
}
.actionsContainer {
  display: flex;
  flex-direction: row;
  gap: $size-m;
}
.serviceHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.serviceText {
  display: flex;
  flex-direction: column;
  margin-bottom: $size-m;
}
.serviceDescription {
  text-overflow: ellipsis;
  word-break: break-word;
  hyphens: auto;
}
.serviceName {
  display: flex;
  flex-direction: row;
  gap: $size-m;
  word-break: break-word;
  hyphens: auto;
}
.amountIndicator {
  color: $color-ui-blue-60;
  display: flex;
  flex-wrap: nowrap;
}
.serviceActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $size-s;

  .serviceActionsCounter {
    margin: 0 $size-s 0 0;
  }
}
.summaryMain {
  display: flex;
  flex-direction: column;
}
.summaryHeader {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.summaryContainer {
  display: flex;
  flex-direction: row;
}
.summaryContent {
  display: flex;
  gap: $size-m;
}
.summaryGrid {
  flex-direction: row;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  display: grid;
}
.summaryList {
  flex-direction: column;
  width: 100%;
}
.serviceDetails {
  flex-direction: row;
  display: flex;
  flex: 2;
  gap: $size-m;
  &.column {
    flex-direction: column;
    gap: 0px;
  }
}
.serviceCard {
  margin-bottom: $size-m;
  gap: $size-m;
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: $size-m;
  border-radius: $size-s;
  background-color: white;
  justify-content: space-between;
  box-shadow: 0px $size-s $size-s rgba(65, 133, 164, 0.1);
}

.summarySection {
  display: flex;
  justify-content: space-between;
  gap: $size-m;
  align-items: center;
}
.gridElement {
  flex-direction: column;
  .summarySection {
    flex-direction: row;
  }
}
.summaryMenu {
  position: relative;
  align-self: baseline;
}
.listElement {
  .summarySection {
    flex-direction: row;
  }
  .summaryMenu {
    align-self: center;
  }
  justify-content: space-between;
}
.menuItem {
  i {
    font-size: 20px !important;
    width: 20px !important;
    height: 20px !important;
    color: $color-ui-blue-60 !important;
  }
}
.menuItemOutline {
  i {
    font-size: 20px !important;
    width: 20px !important;
    height: 20px !important;
    color: transparent !important;
    -webkit-text-stroke-color: $color-ui-blue-60;
    -webkit-text-stroke-width: 1px;
  }
}
.redOutline {
  i {
    -webkit-text-stroke-color: $color-status-red;
  }
}
.addToGroupButton {
  color: white !important;
  border: 0px !important;
  background-color: $color-blue-90 !important;
  & > div > span {
    &:first-child {
      top: 12px !important;
      left: initial !important;
      right: 18px !important;
      padding-left: 6px;
      border-left: 1px solid rgba(255, 255, 255, 0.5);
      font-weight: 700;
    }
    &:last-child {
      margin-left: 8px !important;
      margin-right: 38px !important;
    }
  }
  :hover {
    background-color: $color-blue-80 !important;
    border-radius: 4px;
  }
  :active {
    background-color: $color-blue-90 !important;
    border-radius: 4px;
  }
}