@import "../../styles/vars.scss";
.container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .icon {
    color: $color-black-primary;
  }
}
.main{
  flex-direction: column;
  display: flex;
  width: 100%;
  height: 100%;
}
.grid{
  height:100%
}