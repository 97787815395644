@import "styles/vars.scss";

.listInputWrapper:first {
  margin-left: $size-m;
}

.stepContainer {
  width: 100%;
}

.largeStepContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  width: 100%;
  column-gap: $size-m;
  // Hack to keep padding-bottom a scroll bar shows up
  margin-bottom: -$size-m;
  &::after {
    content: "";
    height: $size-m;
  }
}

.listInputWrapper {
  width: 100%;
}

.summaryContainer {
  flex: 1;
  padding: 45px 50px;
  background-color: $color-white-primary;
  align-items: flex-start;
  align-content: flex-start;
}

.summaryList {
  display: grid;
  gap: $size-m;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
}

.summaryItem {
  overflow: hidden;
  word-break: break-word;
  flex: 0 0 33%;
  margin-bottom: 18px;
  padding-bottom: 12px;
  border-bottom: 1px solid $color-grey-20;

  .label {
    font-size: $font-size-xs;
  }
  .value {
    font-size: $font-size-s;
    font-family: $font-abb-medium;
  }
}

.wizardCheckbox {
  margin-left: 0;
  margin-bottom: 16px;
}

.dialogWrapper {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialogContainer {
  width: 40%;
}

.locationTreeContainer {
  margin-top: 4px;
  background: #fff;
  border: 1px solid $color-grey-30;
  max-height: 70vh;
  overflow: auto;
}

.locationTreeLabel {
  color: $color-grey-60;
}

.required {
  color: $color-status-red;
}
