@import "../../../../../../styles/vars.scss";
.root {
  position: relative;
  text-align: left;
  display: block;
  font-size: $font-size-s;
  margin-bottom: $size-m;
  & > .label {
    font-weight: $normal-weight;
    display: block;
    margin-bottom: 4px;
    & > .requiredAsterisk {
      color: $color-status-red;
    }
  }

  & > .inputWrapper {
    position: relative;
    height: $element-large-height;
    display: inline-block;
    border-radius: 3px;
    &.hasFixedSize {
      width: 100%;
    }
    &:not(.hasFixedSize) {
      height: auto;
      width: 100%;
      display: inline-flex;
      & > .inputElement {
        min-height: $element-small-height;
      }
    }
    & > .inputElement {
      box-sizing: border-box;
      height: 100%;
      width: 100%;
      vertical-align: top;
      padding: $size-xs $size-s;
      font-size: $font-size-s;
      font-family: $font-abb-regular;
      text-overflow: ellipsis;
      box-shadow: none;
      outline: none;
      border: 1px solid transparent;
      &:not(.resizable) {
        resize: none;
      }
      &:focus {
        outline: none;
      }
      &:not(:disabled)::placeholder {
        opacity: 0.5;
      }
      &::-webkit-inner-spin-button {
        display: none;
      }
      &[type="number"] {
        -moz-appearance: textfield;
      }
      &::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
      }
    }

    & > .iconContainer {
      position: absolute;
      right: 8px;
      top: 0;
      height: 100%;
      display: flex;
      flex-direction: row;

      & > .clearIcon {
        margin: auto 4px;
        height: 20px;
        width: 20px;
      }
      & > .iconSeparator {
        height: 24px;
        width: 1px;
        margin: auto 0;
        background: $color-grey-30;
      }
      & > .iconWrapper {
        box-sizing: border-box;
        margin: auto 4px;
        height: 20px;
        width: 20px;
        padding: 2px;
      }
    }
  }
  &.disabled > .inputWrapper > .iconContainer > .iconWrapper {
    opacity: 0.5;
  }

  &:hover > .inputWrapper > .iconContainer {
    & > .iconSeparator {
      background: $color-grey-40;
    }
  }
  &.hasIcon > .inputWrapper > .inputElement {
    padding-right: 40px;
  }

  &.hasClearIcon {
    &.hasIcon > .inputWrapper > .inputElement {
      padding-right: 66px;
    }
    &:not(.hasIcon) > .inputWrapper > .inputElement {
      padding-right: 36px;
    }
  }

  & > .validationMessageContainer {
    max-height: 0px;
    opacity: 0;
    overflow: hidden;
    &.visible {
      max-height: 300px;
      opacity: 1;
    }
    transition: max-height 0.3s, opacity 0.3s;
    margin-top: 4px;
    &.hidden {
      opacity: 0;
    }
    &.validationCollapsed {
      visibility: hidden;
      height: 0;
    }
    display: flex;
    flex-direction: row;
    margin-top: 4px;
    color: $color-grey-70;
    & > .validationIconWrapper {
      margin-right: 6px;
      height: 16px;
      display: flex;
      align-items: center;
      cursor: default;
      &.validationIconWrapperError {
        color: $color-status-error;
      }
      &.validationIconWrapperValid {
        color: $color-status-ok;
      }
    }
    & > .validationMessage {
      font-weight: 500;
      font-size: $font-size-xs;
    }
  }

  & > .inputWrapper:after {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: transparent;
    pointer-events: none;
  }

  &.isValid.showValidationResult > .inputWrapper:after {
    background: $color-status-ok;
  }

  &.hasChanged > .inputWrapper:after {
    background: $color-ui-blue-60;
  }

  &.isInvalid.showValidationResult > .inputWrapper:after {
    background: $color-status-error;
  }

  & > .description {
    font-size: $font-size-xs;
    margin-top: 4px;
  }

  &.normal {
    & > .inputWrapper {
      & > .inputElement {
        &:disabled {
          opacity: 0.5;
        }
      }
    }
  }

  &.discreet {
    &.light {
      & > .inputWrapper {
        & > .inputElement {
          border-bottom-color: $input-border-on-light;
          border-bottom-style: solid;
          border-bottom-width: 1px;
        }
      }
    }
    &.dark {
      & > .inputWrapper {
        & > .inputElement {
          border-bottom-color: $input-border-on-dark;
          border-bottom-style: solid;
          border-bottom-width: 1px;
        }
      }
    }
    & > .inputWrapper {
      & > .inputElement {
        border-top-color: transparent;
        border-left-color: transparent;
        border-right-color: transparent;
        background: transparent;
      }
    }
    &:focus {
      & > .inputWrapper > .inputElement:after {
        border-color: $color-ui-blue-60;
      }
    }
  }

  &.light {
    & > .label,
    & > .description {
      color: $secondary-text-on-light;
    }
    & > .inputWrapper > .iconContainer {
      color: $secondary-text-on-light;
    }

    & > .inputWrapper {
      & > .inputElement {
        border-color: $input-border-on-light;
        &:hover {
          border-color: $input-border-on-light-hover;
        }
        &:focus {
          border-color: $color-ui-blue-60;
        }
      }
    }
    &.normal {
      & > .inputWrapper {
        & > .inputElement {
          border-color: $input-border-on-light;
          &:disabled {
            background-color: $color-grey-10;
            color: rgba($color-grey-90, 0.5);
          }
          &:hover {
            background-color: $input-bg-on-light-hover;
            border-color: $input-border-on-light-hover;
          }
          &:focus {
            border-color: $color-ui-blue-60;
          }
        }
      }
    }
    &.discreet {
      & > .inputWrapper {
        & > .inputElement {
          border-top-color: transparent;
          border-left-color: transparent;
          border-right-color: transparent;
          border-bottom-color: $color-grey-30;
          color: $primary-text-on-light;
          &:disabled {
            color: rgba($color-grey-90, 0.5);
          }
          &:hover {
            border-bottom-color: $color-grey-40;
          }
          &:focus {
            border-bottom-color: $color-ui-blue-60;
          }
        }
      }
    }
  }

  &.dark {
    & > .label,
    & > .description {
      color: $secondary-text-on-dark;
    }
    & > .inputWrapper > .iconContainer {
      color: $secondary-text-on-dark;
      & > .iconSeparator {
        background: $color-grey-60;
      }
    }

    & > .inputWrapper {
      & > .inputElement {
        color: $primary-text-on-dark;
        border-color: $input-border-on-dark;
        &:hover {
          border-color: $input-border-on-dark-hover;
        }
        &:focus {
          border-color: $color-ui-blue-60;
        }
      }
    }
    &.normal {
      & > .inputWrapper {
        & > .inputElement {
          background: $color-content-background-dark;
          border-color: $input-border-on-dark;
          &:disabled {
            background-color: $color-grey-80;
            color: rgba($color-grey-20, 0.5);
          }
          &:hover {
            background-color: $input-bg-on-dark-hover;
            border-color: $input-border-on-dark-hover;
          }
          &:focus {
            background-color: $input-bg-on-dark-pressed;
            border-color: $color-ui-blue-60;
          }
        }
      }
    }
    &.discreet {
      & > .inputWrapper {
        & > .inputElement {
          border-top-color: transparent;
          border-left-color: transparent;
          border-right-color: transparent;
          border-bottom-color: $input-border-on-dark;
          &:disabled {
            color: rgba($color-grey-20, 0.5);
          }
          &:hover {
            border-bottom-color: $color-grey-60;
          }
          &:focus {
            border-bottom-color: $color-ui-blue-60;
          }
        }
      }
    }
  }
}
