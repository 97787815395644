@import "../../styles/vars.scss";
.tagList {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  max-width: 100%;
  overflow: hidden;
  li {
    font-size: $font-size-s;
    padding: $size-xxs $size-xs;
    margin-right: $size-xs;

    display: inline;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    border: 1px solid $color-status-blue;
    border-radius: 3px;
  }
}
.tagListInPopup {
  list-style-type: none;
  margin: 0;
  padding: $size-xs;
  border-radius: $size-xs;
  li {
    font-size: $font-size-s;
  }
}
