@import "../../styles/vars.scss";
$listContainer-padding: $size-m;
.container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
}
.addFormContainer {
  flex: 1 1 auto;
  display: flex;
  gap: $size-m;
  align-items: center;
}

.textInputContainer {
  flex: 1 1 auto;
}

.listContainer {
  background-color: $color-white-primary;
  border: 1px solid $color-grey-30;
  height: 100%;
  padding: $listContainer-padding;
  margin-top: $size-s;
  ul {
    padding: 0;
  }

  li {
    margin-bottom: $size-s;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    word-break: break-all;
    gap: $listContainer-padding;
    & > i {
      cursor: pointer;
      margin-top: $size-xs;
    }
  }
}
