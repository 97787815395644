@import "../../styles/vars.scss";

.expandableText {
  display: flex;
}

.text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1 1 auto;
}

.icons {
  flex: 0 0 auto;
  gap: $size-xs;
  display: flex;
  align-items: center;
  * {
    cursor: pointer;
  }
}

.textExpanded {
  word-break: break-all;
  overflow: auto;
  text-overflow: initial;
}
