.navigation{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width:100%
}
.navigationItems{
  display: flex;
  flex-direction: row;
}
.navigationActions{
  display: flex;
  flex-direction: row;
}