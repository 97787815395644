@import "../../../styles/vars.scss";

.removeContainer {
  color: $color-brand-red;
  cursor: pointer;
  span {
    padding-left: $size-s;
  }
}
.form {
  display: flex;
  flex-direction: column;
  padding: $size-s 0px $size-m 0px;

  .selectContainer {
    padding: 0px 30px;
  }
  .dropdown {
    margin-bottom: $size-m;
    // Fix ABB CommonUX Dropdown cutting letters like 'p' or 'q'
    :global(.ABB_CommonUX_Dropdown__comp > div > div > div:first-child) {
      min-height: 48px;
    }
    :global(:not(.ABB_CommonUX_Dropdown__validationIconWrapper)) {
      > i {
        position: relative;
        right: -$size-xs;
        margin: 0px 0px 0px (-$size-s);
        width: $size-ml !important;
        height: $size-ml !important;
        font-family: ABB-UI-Icons-24x24 !important;
        font-size: $size-ml !important;
      }
    }
  }
  :global(.ABB_CommonUX_Input__validationMessageContainer) {
    position: absolute;
  }
  .checkboxForm {
    margin-left: 0px;
    margin-bottom: $size-m;
  }

  .passswordTip {
    font-size: $fontSizeSm;
    margin-bottom: $size-m;
    color: $color-grey-40
  }

  :global(.ABB_CommonUX_Dropdown__root) {
    margin-bottom: $size-m;
  }
}
.resetPassword {
  margin-bottom: $size-m;
}
.buttons {
  display: flex;
  flex-direction: row;
  padding-top: $size-l;
  .secondary {
    padding-right: $size-s;
    width: 100%;
  }
  .primary {
    padding-left: $size-s;
    width: 100%;
  }
  .button {
    width: 100%;
  }
}
