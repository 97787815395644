@import "../../styles/vars.scss";

.container {
  width: 100%;
  height: 100%;
  background-color: $color-ui-blue-10-no-official;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
