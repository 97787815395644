@import "../../../styles/vars.scss";

.panel {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.buttons {
  display: flex;
  flex-direction: row;
  padding-top: $size-l;
  .secondary {
    padding-right: $size-s;
    width: 100%;
  }
  .primary {
    padding-left: $size-s;
    width: 100%;
  }
  .button {
    width: 100%;
  }
}

.topics > * {
  margin-bottom: $size-s;
}

.labeledPropertyValue {
  overflow-wrap: break-word;
  white-space: normal;
}

.labeledPropertyContainer {
  margin-top: $size-s;
}

.dialogContainer {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}