@import "styles/vars.scss";

.actions {
  gap: $size-s;
  align-items: center;
}
.actionWrapper {
  opacity: 0;
  transition: all 100ms ease;
  &:hover {
    opacity: 1;
  }
}
