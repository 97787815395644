@import "styles/vars.scss";

.dataGridContainer {
  padding: 15px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 10px 10px rgba(65, 133, 164, 0.1);
}
.search {
  margin-bottom: 0px;
  :global(.ABB_CommonUX_Input__inputWrapper) {
    height: auto !important;
  }
}
.filterButton {
  padding: 2px;
  color: transparent !important;
  -webkit-text-stroke-color: white;
  -webkit-text-stroke-width: 2px;
  border: 0px !important;
  background-color: $color-blue-90 !important;
  :hover {
    background-color: $color-blue-80 !important;
    border-radius: 4px;
  }
  :active {
    background-color: $color-blue-90 !important;
    border-radius: 4px;
  }
}
.dialogContainer {
  width: 40%;
}

.headerButtons {
  display: flex;
  flex-direction: row;
  gap: 16px;  
  justify-content: flex-end;
}

.assetActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: $size-s;
}

.assetActionsCounter {
  margin: 0 $size-s 0 0;
}
