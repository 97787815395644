@import "styles/vars.scss";

.navigation {
  display: flex;
  line-height: 1.5;
  button {
    border: 0;
  }
  ol {
    list-style: none;
  }
  display: flex;
  flex-wrap: wrap;
  .cancel {
    padding: $size-xs;
  }
  .link {
    position: relative;
    z-index: 100;
    transition: color 0.25s linear;
    background: transparent;
  }
  .activeLink {
    color: $color-status-blue;
  }
  .disabledLink {
    color: $color-grey-40;
  }
  .line {
    flex: 1;
    position: relative;
    ol {
      box-sizing: border-box;
      width: 100%;
      border-bottom: 2px solid $color-grey-10;
      display: flex;
      flex-wrap: wrap;
      white-space: nowrap;
      justify-content: space-between;
      li {
        a {
          color: $color-black-primary;
          text-decoration: none;
        }
        flex: 1;
        position: relative;
        text-align: center;
        color: $color-grey-50;
        &:after {
          position: relative;
          z-index: 10;
          content: "•";
          display: block;
          font-size: $font-size-xl;
          color: $color-grey-30;
          top: -$size-m;
        }
        &.completed {
          .activeLine {
            display: block;
            width: 100%;
          }
          &:after {
            font-size: $font-size-xl;
            color: $color-status-blue;
          }
        }
        &:last-child {
          .baseLine {
            display: none;
          }
        }
        &.active {
          font-weight: bold;
          .activeLine {
            display: block;
            width: 0%;
          }
          &:last-child {
            .activeLine {
              display: none;
            }
          }
          &:after {
            font-size: $font-size-xl;
            color: $color-status-blue;
          }
        }
        &.completed,
        &.active {
          color: $color-grey-80;
        }
        &.disabled {
          color: $color-grey-40;
        }
      }
    }
    .baseLine {
      z-index: 5;
      position: absolute;
      left: 50%;
      width: 100%;
      border-bottom: $size-xxs solid $color-grey-30;
      margin-top: 1px;
      top: $size-l;
    }
    .activeLine {
      z-index: 5;
      position: absolute;
      width: 0%;
      left: 50%;
      transition: width 0.25s linear;
      border-bottom: $size-xs solid $color-status-blue;
      top: $size-l;
    }
  }
}
