// COLORS
$color-blue-100: #031324;
$color-blue-90: #00284a;
$color-blue-80: #004480;
$color-blue-70: #005ca3;
$color-blue-60: #1b7ac2;
$color-blue-50: #00acec;
$color-blue-40: #2fc8f7;
$color-blue-30: #6cd5ff;
$color-blue-20: #b3e6ff;
$color-blue-10: #def4fa;
$color-purple-100: #240024;
$color-purple-90: #4a014a;
$color-purple-80: #7a097a;
$color-purple-70: #8f188f;
$color-purple-60: #a13fa1;
$color-purple-50: #cc50cc;
$color-purple-40: #e670e6;
$color-purple-30: #f291f2;
$color-purple-20: #fac5fa;
$color-purple-10: #fae3fa;
$color-magenta-100: #2e0016;
$color-magenta-90: #52032a;
$color-magenta-80: #820d48;
$color-magenta-70: #a8135d;
$color-magenta-60: #c2347b;
$color-magenta-50: #e64997;
$color-magenta-40: #ff6bb5;
$color-magenta-30: #ff94c9;
$color-magenta-20: #ffc7e4;
$color-magenta-10: #ffe3f0;
$color-teal-100: #001612;
$color-teal-90: #082823;
$color-teal-80: #16453d;
$color-teal-70: #195c50;
$color-teal-60: #3b786e;
$color-teal-50: #3da090;
$color-teal-40: #5abfae;
$color-teal-30: #7ed6c6;
$color-teal-20: #b4eae1;
$color-teal-10: #d7f7f3;
$color-green-100: #011900;
$color-green-90: #10290e;
$color-green-80: #1a4717;
$color-green-70: #1e6119;
$color-green-60: #477a43;
$color-green-50: #51a34b;
$color-green-40: #75ba70;
$color-green-30: #98d694;
$color-green-20: #bfedbb;
$color-green-10: #e2f7df;
$color-yellow-100: #111100;
$color-yellow-90: #232101;
$color-yellow-80: #3a3813;
$color-yellow-70: #524c00;
$color-yellow-60: #756e09;
$color-yellow-50: #968d0a;
$color-yellow-40: #b5aa0d;
$color-yellow-30: #d6c918;
$color-yellow-20: #ede56f;
$color-yellow-10: #faf5b6;
$color-gold-100: #1a0e00;
$color-gold-90: #331a02;
$color-gold-80: #542c04;
$color-gold-70: #753b00;
$color-gold-60: #a65503;
$color-gold-50: #c17120;
$color-gold-40: #d98f45;
$color-gold-30: #f2ac66;
$color-gold-20: #ffd3a8;
$color-gold-10: #ffeddb;
$color-orange-100: #290700;
$color-orange-90: #4d0d00;
$color-orange-80: #751a08;
$color-orange-70: #a82005;
$color-orange-60: #d93616;
$color-orange-50: #ed5739;
$color-orange-40: #f57c64;
$color-orange-30: #ff9f8c;
$color-orange-20: #ffcec4;
$color-orange-10: #ffeae8;
$color-red-100: #3e0000;
$color-red-90: #5c0006;
$color-red-80: #a30005;
$color-red-70: #cc0815;
$color-red-60: #e12e2e;
$color-red-50: #ff4754;
$color-red-40: #ff757e;
$color-red-30: #ff99a0;
$color-red-20: #ffc9cd;
$color-red-10: #ffe8e9;
// color-grey:
$color-grey-100: #0f0f0f;
$color-grey-90: #1f1f1f;
$color-grey-80: #333333;
$color-grey-70: #464646;
$color-grey-60: #696969;
$color-grey-50: #868686;
$color-grey-40: #9f9f9f;
$color-grey-30: #bababa;
$color-grey-20: #dbdbdb;
$color-grey-10: #ebebeb;
// color-status:
$color-status-red: #f03040;
$color-status-magenta: #cb2bd5;
$color-status-orange: #ff7300;
$color-status-blue: #0052ff;
$color-status-yellow: #ffd800;
$color-status-green: #0ca919;
// color-brand:
$color-brand-black: #000000;
$color-brand-red: #ff000f;
$color-brand-white: #ffffff;
// color-white:
$color-white-primary: #ffffff;
$color-white-secondary: #fafafa;
$color-white-tertiary: #f5f5f5;
// color-black:
$color-black-primary: #000000;
$color-black-secondary: #050505;
$color-black-tertiary: #0a0a0a;
// color-ui-blue:
$color-ui-blue-100: #080261;
$color-ui-blue-90: #150c9b;
$color-ui-blue-80: #2c20d4;
$color-ui-blue-70: #2a35ff;
$color-ui-blue-60: #3366ff;
$color-ui-blue-50: #4c85ff;
$color-ui-blue-40: #77a3fc;
$color-ui-blue-30: #9ebef7;
$color-ui-blue-20: #c5dff9;
$color-ui-blue-10: #e0f0ff;

// NON-OFFICIAL COLORS
$color-ui-blue-10-no-official: #f4f7fc;
$color-ui-app-launcher-black: #212529;
$color-link: #007bff;

// FONTS
$fontSizeLg: 1.5rem;
$fontSizeSm: 0.8rem;

$line-height-xxs: 12px;
$line-height-xs: 14px;
$line-height-s: 16px;
$line-height-m: 20px;
$line-height-xl: 24px;

$font-size-xxs: 10px;
$font-size-xs: 12px;
$font-size-s: 14px;
$font-size-m: 16px;
$font-size-ml: 20px;
$font-size-l: 24px;
$font-size-xl: 32px;
$font-size-xxl: 40px;

// SIZES
$size-xxs: 2px;
$size-xs: 4px;
$size-s: 8px;
$size-ms: 12px;
$size-m: 16px;
$size-ml: 24px;
$size-l: 32px;
$size-xl: 40px;
$size-xxl: 48px;

// HEIGHTS
$element-small-height: 32px;
$element-medium-height: 40px;
$element-large-height: 48px;

// FONT-WEIGHT
$normal-weight: 400;

// FONT-FAMILY
$font-abb-regular: "ABBVoice";
$font-abb-light: "ABBVoice-light";
$font-abb-medium: "ABBVoice-medium";
$font-abb-bold: "ABBVoice-bold";
$font-icon-s: "ABB-UI-Icons-16x16";
$font-icon-m: "ABB-UI-Icons-24x24";
$font-icon-l: "ABB-UI-Icons-32x32";

// WEB-ONLY (EXTRACTED FROM COMMONUX)
$color-app-background-light: $color-grey-10;
$color-app-background-dark: $color-grey-100;
$color-content-background-light: $color-white-tertiary;
$color-content-background-dark: $color-grey-80;
$color-accent-light: $color-ui-blue-60;
$color-accent-light-hover: $color-ui-blue-70;
$color-accent-light-pressed: $color-ui-blue-80;
$color-accent-dark: $color-ui-blue-60;
$color-accent-dark-hover: $color-ui-blue-50;
$color-accent-dark-pressed: $color-ui-blue-40;
$primary-text-on-light: $color-grey-90;
$primary-text-on-dark: $color-grey-20;
$secondary-text-on-light: $color-grey-60;
$secondary-text-on-dark: $color-grey-50;
$input-bg-on-light: $color-white-primary;
$input-bg-on-light-hover: $color-grey-10;
$input-bg-on-light-pressed: $color-grey-20;
$input-border-on-light: $color-grey-30;
$input-border-on-light-hover: $color-grey-20;
$input-border-on-light-pressed: $color-grey-50;
$input-bg-on-dark: $color-grey-90;
$input-bg-on-dark-hover: $color-grey-80;
$input-bg-on-dark-pressed: $color-grey-70;
$input-border-on-dark: $color-grey-70;
$input-border-on-dark-hover: $color-grey-60;
$input-border-on-dark-pressed: $color-grey-50;
$text-on-color: $color-white-primary;
$color-status-ok: $color-status-green;
$color-status-error: $color-status-red;
$color-status-warning: $color-status-orange;
$full-opacity-on-light: $color-white-primary;
$full-opacity-on-dark: $color-grey-80;