@import "../../../styles/vars.scss";

.buttons {
  display: flex;
  flex-direction: row;
  padding-top: $size-l;
  .secondary {
    padding-right: $size-s;
    width: 100%;
  }
  .primary {
    padding-left: $size-s;
    width: 100%;
  }
  .button {
    width: 100%;
  }
}
