@import "styles/vars.scss";
.searchFilterContainer {
  padding: $size-xs;
  display: flex;
  flex-direction: row;
  gap: $size-xxs;
}
.sidebarContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0px 8px;
  height: 100%;
}
.assetItem {
  padding: $size-s $size-xs $size-s $size-ml;
  color: black;
  position: relative;
  cursor: pointer;
  text-overflow: ellipsis;
  display: flex;
  justify-content: space-between;
  word-break: break-word;
  width: 100%;
  font-size: $font-size-s;

  a {
    color: $color-grey-40;
    transition: color 0.35s ease-out;
  }
  a:hover {
    color: $color-grey-60;
  }
  &::before {
    border: 0px solid $color-ui-blue-60;
    //transition: opacity 0.1s ease-in, border-width 0.1s ease-in;
    opacity: 0;
    position: absolute;
    content: "";
    top: $size-s;
    bottom: $size-s;
    left: $size-m;
  }
  &:hover {
    background-color: $color-grey-30;
  }
}
.selected {
  background-color: $color-grey-20;
  &::before {
    border-left: $size-xs solid $color-ui-blue-60;
    opacity: 1;
  }
}
.search {
  padding: $size-s;
  margin: 0px;
  &:global(.ABB_CommonUX_Input__root > .ABB_CommonUX_Input__inputWrapper) {
    height: auto;
  }
}
.title {
  padding-left: $size-m;
}
