@import "../../../styles/vars.scss";

.removeContainer {
  color: $color-brand-red;
  cursor: pointer;
  span {
    padding-left: $size-s;
  }
}
.form {
  padding: $size-s 0px $size-m 0px;

  .selectContainer {
    padding: 0px 30px;
  }

  i {
    position: relative;
    right: -$size-xs;
    margin: (-$size-xxs) 0px 0px (-$size-s);
    width: $size-ml !important;
    height: $size-ml !important;
    font-family: ABB-UI-Icons-24x24 !important;
    font-size: $size-ml !important;
  }

  :global(.ABB_CommonUX_Dropdown__root) {
    margin-bottom: $size-m;
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  padding-top: $size-m;
  .secondary {
    padding-right: $size-s;
    width: 100%;
  }
  .primary {
    padding-left: $size-s;
    width: 100%;
  }
  .button {
    width: 100%;
  }
}
