@import "../../styles/vars.scss";

.elementNumber {
  color: $color-ui-blue-60;
}
.buttons {
  display: flex;
  width: 100%;
  padding-top: $size-l;

  .secondary {
    padding-right: $size-s;
    width: 100%;
  }

  .primary {
    padding-left: $size-s;
    width: 100%;
  }
  .button {
    width: 100%;
  }
}
