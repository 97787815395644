.loading-box {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100px;
  align-items: center;
  &.is-full-screen {
    height: 100%;
  }
  &.is-full-small {
    height: 50px;
  }
  &.is-full-medium {
    height: 100px;
  }
  &.is-full-large {
    height: 200px;
  }
}
