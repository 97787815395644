@import "../../../styles/vars.scss";

.form {
  display: flex;
  flex-direction: column;
  padding-top: $size-m;
  :nth-child(1),
  :nth-child(2) {
    margin-bottom: 2px;
    span :first-of-type {
      height: $size-l;
    }
  }
  i {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-family: ABB-UI-Icons-24x24;
    font-size: $size-m;
    margin-top: 2px;
  }

  :global(.ABB_CommonUX_Dropdown__root) {
    margin-bottom: $size-ml;
  }
  :global(.ABB_CommonUX_Dropdown__root > div :first-of-type) {
    min-height: 18px;
    align-items: center;
    align-content: center;
  }
  :global(.ABB_CommonUX_Dropdown__root > div > div > div > div > *) {
    max-height: 48px;
    margin-bottom: 2px;
  }
  :global(.ABB_CommonUX_Dropdown__comp > div > div > div > div > div > div) {
    margin-bottom: 7px;
  }
  :global(.ABB_CommonUX_Dropdown__comp
      > div
      > div
      > div
      > div
      > div:first-child) {
    padding-top: 6px;
    margin-bottom: 8px;
  }
}

.clearButton {
  height: $size-ml !important;
  div {
    padding: 0px !important;
  }
}
