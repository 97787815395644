@import "../../styles/vars.scss";

.container {
  position: relative;
  .floatingForm {
    position: absolute;
    width: 300px;
    z-index: 1;
    .innerContainer {
      background-color: $color-brand-white;
      border: 1px solid $color-grey-30;
      border-radius: 3px;
      box-shadow: 0px $size-xs 10px $color-grey-30;

      .title {
        font-weight: bold;
        padding: $size-m $size-m 0px $size-m;
      }
      .treeContainer {
        padding: $size-m;
        > div {
          border: 1px solid $color-grey-30;
          box-shadow: none;
          max-height: 500px;
          overflow-y: auto;
        }
        .required {
          color: $color-status-red;
        }
      }
      .buttons {
        display: flex;
        flex-direction: row;
        padding: $size-m;
        .secondary {
          padding-right: $size-s;
          width: 100%;
        }
        .primary {
          padding-left: $size-s;
          width: 100%;
        }
        .button {
          width: 100%;
        }
      }
    }
  }
}
