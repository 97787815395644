@import "../../styles/vars.scss";


.applications {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(199px, 1fr));
  justify-items: center;
  width: 100%;
  height: fit-content;
}

.errorApps{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}