.container {
  // Ugly workaround to make the modal header wider without breaking other CommonUX components
  &
    > div:first-child
    > div:first-child
    > div:first-child
    > div:first-child
    > div:first-child
    > div:first-child
    > div:first-child
    > div:first-child
    > div:first-child {
    flex-grow: 20 !important;
  }
}
