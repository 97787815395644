@import "styles/vars.scss";

.summaryItem {
  overflow: hidden;
  word-break: break-word;
  flex: 0 0 33%;
  margin-bottom: 18px;
  padding-bottom: 12px;
  border-bottom: 1px solid $color-grey-20;

  .label {
    font-size: $font-size-xs;
  }
  .value,
  .arrayValue {
    font-size: $font-size-s;
    font-family: $font-abb-medium;
  }

  .arrayValue {
    list-style: none;
    padding-left: 0;
    li {
      margin-bottom: $size-m;
    }
  }
}
