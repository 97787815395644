@import "../../styles/vars.scss";

.popup {
  background-color: $color-white-tertiary;
  border: 1px solid $color-black-primary;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: $size-s;
  font-size: $font-size-s;
  p{
    margin:0;
  }
}


.infoButton{
  padding-left: $size-s;
  div{
  color: $color-grey-60
  }
}
.infoButton div:hover{
  color: $color-black-primary
}