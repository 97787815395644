@import "../../styles/vars";

.label {
    display: block;
    font-size: .9rem;
    color: $color-grey-60;
    input {
        display: none;
    }
}
.area {
    margin-top: 4px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: .8rem;
    border: $color-grey-20 2px dotted;
    background: #fff;
}
.areaWithFile {
    color: $color-grey-90
}
.clearBtn {
    position: absolute;
    right: 0;
    padding: 5px 0 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.displayNone {
    display: none;
}

.helperText {
    color: $color-grey-60;
    font-size: $font-size-xs;
    margin-top: -$size-m;
    padding-left: $size-xs;
    position: absolute;
}