@import "../../styles/vars.scss";

.mainLayout {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}
.topNavigation {
  border-bottom: 1px solid #f2f3f6;
  display: flex;
  flex-direction: column;
}
.mainArea {
  flex-grow: 10;
  max-height: calc(100vh - 73px);
}
.footer {
  flex: 0 0 0%;
  padding: 0 $size-m;
  background-color: $color-app-background-light;
}
