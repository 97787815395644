@import '../../styles/vars.scss';

.slideContainer {
    :global {
        .simplebar-content {
            height: inherit;

            > div {
                height: inherit;
            }
            
        }
    }
   
    .form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: inherit;

        .bottomForm {
            .informationSection {
                display: flex;
                flex-direction: row;
                align-items: baseline;
                gap: $size-xs;

                .infoIcon {
                    padding: 0;
                }
    
                .label {
                    color: $color-grey-60;
                    font-size: $font-size-xs;
                }
            }

            .downloadSample {
                display: flex;
                gap: $size-xs;
                align-items: baseline;
                color: $color-link;
            }
        }
    }
}