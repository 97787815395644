@import "../../styles/vars.scss";
.select {
  z-index: 1;
  background-color: $color-brand-white;
  margin-top: $size-s;
  border: 1px solid $color-grey-30;
  border-radius: 3px;
  padding: $size-m;
  box-shadow: 0px 4px 10px $color-grey-30;
  width: 100%;
  .searchContainer {
    padding: $size-s;
    display: flex;
  }
  .optionContainer {
    display: flex;
    align-items: center;
    .checkBoxOption {
      margin-left: 0px;
    }
    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow-x: hidden;
      font-family: ABBvoice;
      font-size: $font-size-s;
      color: $color-grey-90;
    }
  }
}
