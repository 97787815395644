@import "styles/vars.scss";

.container {
  height: 32px;
  display: flex;
  gap: $size-s;
  align-items: center;
  * {
    font-size: $size-m;
    font-family: $font-abb-medium;
  }

  .text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
