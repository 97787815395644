@import "styles/vars.scss";

.actionsContainer {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  gap: $size-m;
}

.groupHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.groupText {
  display: flex;
  flex-direction: row;
  gap: $size-s;
}

.groupDescription {
  text-overflow: ellipsis;
  word-break: break-word;
  hyphens: auto;
}

.groupName {
  display: flex;
  flex-direction: row;
  gap: $size-m;
  word-break: break-word;
  hyphens: auto;
}

.amountIndicator {
  color: $color-ui-blue-60;
  display: flex;
  flex-wrap: nowrap;
  padding-left: $size-s;
}

.groupActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $size-s;

  .groupActionsCounter {
    margin: 0 $size-s 0 0;
  }
}

.summaryMain {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.summaryHeader {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.summaryContainer {
  display: flex;
  flex-direction: row;
}

.summaryContent {
  display: flex;
  flex-wrap: wrap;
  gap: $size-m;
}

.summaryGrid {
  flex-direction: row;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  display: grid;

  .tags {
    flex-wrap: wrap;
    gap: $size-xxs;
  }
}

.summaryList {
  flex-direction: column;
  width: 100%;

  .tags {
    flex-wrap: wrap;
    gap: $size-xxs;
  }

  .summaryRight {
    margin-left: auto;
  }
}

.groupDetails {
  flex-direction: row;
  display: flex;
  flex: 2;
  flex-wrap: wrap;
  gap: $size-m;

  &.column {
    flex-direction: column;
    gap: 0px;
  }
}

.groupCard {
  display: flex;
  gap: $size-m;
  justify-content: space-between;
}

@media (max-width: 800px) {
  .groupCard {
    flex-wrap: wrap;
  }
}

.summarySection {
  display: flex;
  justify-content: space-between;
  gap: $size-m;
  align-items: center;
}

.gridElement {
  max-width: 600px;
  flex-direction: column;
  border-width: 1px;
  .summarySection {
    flex-direction: row;
  }
}

.summaryMenu {
  position: relative;
  align-self: baseline;
}

.listElement {
  .summarySection {
    flex-direction: row;
  }

  .summaryMenu {
    align-self: center;
  }

  justify-content: space-between;
}

.menuItem {
  i {
    font-size: 20px !important;
    width: 20px !important;
    height: 20px !important;
    color: $color-ui-blue-60 !important;
  }
}

.menuItemOutline {
  i {
    font-size: 20px !important;
    width: 20px !important;
    height: 20px !important;
    color: transparent !important;
    -webkit-text-stroke-color: $color-ui-blue-60;
    -webkit-text-stroke-width: 1px;
  }
}

.redOutline {
  i {
    -webkit-text-stroke-color: $color-status-red;
  }
}

.usersSection{
  flex:1;
  display: flex;
  flex-direction: column;
}
.dataGridContainer{
flex:1
}

.addToGroupButton {
  color: white !important;
  border: 0px !important;
  background-color: $color-blue-90 !important;

  &>div>span {
    &:first-child {
      top: 12px !important;
      left: initial !important;
      right: 18px !important;
      padding-left: 6px;
      border-left: 1px solid rgba(255, 255, 255, 0.5);
      font-weight: 700;
    }

    &:last-child {
      margin-left: 8px !important;
      margin-right: 38px !important;
    }
  }

  :hover {
    background-color: $color-blue-80 !important;
    border-radius: 4px;
  }

  :active {
    background-color: $color-blue-90 !important;
    border-radius: 4px;
  }
}