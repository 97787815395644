@import "styles/vars.scss";
.structureTreeItemmenuContent {
  position: relative;
  .ABB_CommonUX_Menu__root {
    position: relative;
  }
}
.header {
  display: flex;
  flex-grow: 1;
  width: 100%;
  .iconsContainer {
    margin-right: $size-s;
    margin-left: auto;
    display: flex;
  }
}
.grabIconContainer {
  padding-right: 10px;
  display: flex;
  align-items: center;
  color: $color-status-blue;
  cursor: move;
  cursor: grab;
}
.grabIconContainer:active {
  cursor: grabbing;
}
.sidebarHeader {
  padding: $size-xs 0px $size-xs $size-m;
  margin-bottom: 0px;
  gap: $size-s;
  display: flex;
  font-size: $size-m;
  font-family: $font-abb-medium;

  & .sidebarHeaderText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.dragCollapsible {
  display: flex;
  align-items: center;
  height: $size-l;
  font-size: $font-size-s;
  flex-direction: row;
  justify-content: space-between;
  padding-left: $size-m;
  transition: opacity 0.3s;
  margin-left: $size-ml;
  margin-right: $size-s;
  p {
    overflow: hidden;
    white-space: nowrap;
    line-height: 1.5;
    align-self: center;
    text-overflow: ellipsis;
    word-break: break-word;
    margin: auto;
    margin-left: $size-s;
  }
  &.isDragging {
    opacity: 0;
    background-color: $color-grey-60;
  }
}
.dragging {
  background-color: $color-grey-20;
  &.canDrop {
    background-color: $color-white-primary;
  }
}
.isOver {
  &.dragging {
    cursor: not-allowed;
  }
  &.canDrop {
    background-color: $color-grey-30;
    cursor: grabbing;
  }
}
.dropContainer {
  &.isDragging {
    background-color: $color-grey-90;
    color: $color-white-primary;
  }
}
.dragChildren {
  padding-left: 24px;
  width: 100%;
}
.menu {
  i {
    color: $color-grey-60 !important;
  }
}
