@import "../../../styles/vars.scss";

.message{
    color: $color-status-warning;
    display: flex;
    flex-direction: row;
    gap: $size-s;
    padding-top: $size-s;
}

.form{
    width: 100%;
    > div{
        z-index: 1020;
    }
}

.input{
    flex-grow:1;
}

.invisible{
    opacity: 0;
}

.dropdown{
    padding-top: 1px;
    margin-left: $size-s;
    flex-grow: 1;
    background-color: $input-bg-on-light;
}
