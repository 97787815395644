@import "styles/vars.scss";
.fixMeatballMenu {
  *[data-cy="meatballMenu.component"] {
    z-index: 101 !important;
  }
}
.sidebarContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0px $size-s;
  height: 100%;
}
.treeItemGroup{
  height: $size-l;
}
.groupsContainer {
  margin-top: $size-ms;
  background-color: transparent;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: $font-size-s;
}

.groupElement {
  padding: $size-s $size-ml;
  color: black;
  position: relative;
  cursor: pointer;
  text-overflow: ellipsis;
  word-break: break-word;
  width: 100%;

  &::before {
    border: 0px solid $color-ui-blue-60;
    //transition: opacity 0.1s ease-in, border-width 0.1s ease-in;
    opacity: 0;
    position: absolute;
    content: "";
    top: $size-s;
    bottom: $size-s;
    left: $size-m;
  }
  &:hover {
    background-color: $color-grey-30;
  }
}
.selectedGroup {
  background-color: $color-grey-20;
  &::before {
    border-left: $size-xs solid $color-ui-blue-60;
    opacity: 1;
  }
}
.search {
  padding: $size-s;
  margin: 0px;
  &:global(.ABB_CommonUX_Input__root > .ABB_CommonUX_Input__inputWrapper) {
    height: auto !important;
  }
}

.structureTreeItemmenuContent {
  position: relative;
  .ABB_CommonUX_Menu__root {
    position: absolute;
  }
}
.selected {
  background-color: $color-white-tertiary;
}
.selected::before {
  content: "";
  border-left: $size-xs solid $color-ui-blue-60;
  position: relative;
  left: -$size-s;
}
.header {
  padding: $size-xs 0px $size-xs $size-m;
  display: flex;
  flex-grow: 1;
  width: 100%;
  h3 {
    margin-bottom: 0px;
  }
  &:hover {
    background-color: $color-ui-blue-10;
    cursor: pointer;
  }
  .iconsContainer {
    margin-right: 0;
    margin-left: auto;
    display: flex;
  }
  .menuIconContainer {
    visibility: hidden;
    color: $color-status-blue;
    display: flex;
    align-items: center;
  }
  &:hover {
    .menuIconContainer {
      visibility: visible;
    }
    .menuIconContainer:hover {
      text-shadow: 0px 0px 5px $color-ui-blue-30, 0px 0px 15px $color-ui-blue-60;
      cursor: pointer;
    }
    .menuIconContainer:active {
      text-shadow: none;
      cursor: default;
    }
  }
  .editIconContainer {
    color: $color-grey-60;
    display: flex;
    align-items: center;
  }
  &:hover {
    .editIconContainer:hover {
      text-shadow: 0px 0px 5px $color-grey-20, 0px 0px 15px $color-grey-80;
      cursor: pointer;
    }
    .editIconContainer:active {
      text-shadow: none;
      cursor: default;
    }
  }
}
.collapsible {
  .menuIconContainer {
    visibility: hidden;
    color: $color-status-blue;
    display: flex;
    align-items: center;
  }
  :global(.ABB_CommonUX_Collapsible__root__trigger):hover {
    .menuIconContainer {
      visibility: visible;
    }
  }
  &:hover {
    .menuIconContainer:hover {
      text-shadow: 0px 0px 5px $color-ui-blue-30, 0px 0px 15px $color-ui-blue-60;
      cursor: pointer;
    }
    .menuIconContainer:active {
      text-shadow: none;
      cursor: default;
    }
  }
}
.grabIconContainer {
  display: flex;
  align-items: center;
  color: $color-status-blue;
  cursor: move;
  cursor: grab;
}
.grabIconContainer:active {
  cursor: grabbing;
}
.dragCollapsible {
  display: flex;
  align-items: center;
  height: $font-size-xl;
  font-size: $font-size-s;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px $font-size-m;
  transition: opacity 0.3s;
  p {
    overflow: hidden;
    white-space: nowrap;
    line-height: 1.5;
    align-self: center;
    text-overflow: ellipsis;
    word-break: break-word;
    margin: auto;
    margin-left: $size-s;
  }
  &.isDragging {
    opacity: 0;
    background-color: $color-ui-blue-80;
  }
}
.dragging {
  background-color: $color-grey-20;
  &.canDrop {
    background-color: $color-white-primary;
  }
}
.isOver {
  &.dragging {
    cursor: not-allowed;
  }
  &.canDrop {
    background-color: $color-ui-blue-30;
    cursor: grabbing;
  }
}
.dropContainer {
  &.isDragging {
    background-color: $color-ui-blue-90;
    color: $color-white-primary;
  }
}
.dragChildren {
  padding-left: $font-size-l;
  width: 100%;
}
.menuItem {
  i {
    font-size: $font-size-ml !important;
    width: $font-size-ml !important;
    height: $font-size-ml !important;
    color: $color-ui-blue-60 !important;
  }
}
.menuItemOutline {
  i {
    font-size: $font-size-ml !important;
    width: $font-size-ml !important;
    height: $font-size-ml !important;
    color: transparent !important;
    -webkit-text-stroke-color: $color-ui-blue-60;
    -webkit-text-stroke-width: 3px;
  }
}

.inputPanel {
  /*...*/
  background: #fff;
  visibility: hidden;
  width: 0px;
  transition: width 1s, visibility 1s;
  z-index: 400;
  position: absolute;
  overflow-x: hidden;
  left: 0;
  top: 0;
  box-shadow: 0px $font-size-ml $font-size-ml grey;
  height: 100%;
  border-left: 1px solid #dfe1e7;
}

.open {
  display: flex;
  flex-direction: column;
  visibility: visible;
  width: 300px;
  transition: width 0.8s, visibility 0.2s;
}
.structureTreeItemRightContent {
  position: relative;
}

.popUp {
  :global(.ABB_CommonUX_Menu__root) {
    position: relative;
  }
  :global(.ABB_CommonUX_Menu__root.ABB_CommonUX_Menu__light) {
    padding: 0px;
  }
}
