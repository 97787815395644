@import "../../../styles/vars.scss";

.removeContainer {
  color: $color-brand-red;
  cursor: pointer;
  span {
    padding-left: $size-s;
  }
}
.form {
  padding: $size-s 0px $size-m 0px;

  .selectContainer {
    padding: 0px 30px;
  }
  .dropdown {
    margin-bottom: $size-m;
    :global(:not(.ABB_CommonUX_Dropdown__validationIconWrapper)) {
      > i {
        position: relative;
        right: -$size-xs;
        width: $size-ml !important;
        height: $size-ml !important;
        margin: 0px 0px 0px (-$size-s);
        font-family: ABB-UI-Icons-24x24 !important;
        font-size: $size-ml !important;
      }
    }
  }
  .description {
    *[class^="Input_inputWrapper"] {
      height: 96px;
    }
  }
  :global(.ABB_CommonUX_Dropdown__root) {
    margin-bottom: $size-m;
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  padding-top: $size-l;

  .secondary {
    padding-right: $size-s;
    width: 100%;
  }
  .primary {
    padding-left: $size-s;
    width: 100%;
  }
  .button {
    width: 100%;
  }
}
