@import "../../styles/vars.scss";

.popupButton {
  color: white;
  border: 0px;
  background-color: $color-blue-90;

  height: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-left: $size-m;
  padding-right: $size-s;
  font-size: $font-size-s;
  cursor: pointer;
  .buttonText {
    padding-left: $size-s;
    padding-right: $size-m;
  }
  .buttonIcon {
    display: flex;
    padding-left: $size-s;
    padding-right: $size-s;

    border-left: 1px solid $color-white-primary;
    font-weight: 700;
  }
  &:hover {
    background-color: $color-blue-80;
    border-radius: $size-xs;
  }
  &:active {
    background-color: $color-blue-90;
    border-radius: $size-xs;
  }
}
