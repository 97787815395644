@import "../../styles/vars.scss";

.container {
}
.label {
    font-size: .8rem;
    margin-bottom: 2px;
    color: $color-grey-50;
}
.value {
    margin: 0;
}