@import "styles/vars.scss";

.fixMeatballMenu {
  *[data-cy="meatballMenu.component"] {
    z-index: 101 !important;
  }
}

.meatballVisible {
  opacity: 1 !important;
}

.draggableItemContainer {
  margin-left: $size-ml;
}

.addEditInput {
  padding: 0 $size-s;
  input {
    width: 100%;
    min-width: 50px;
    height: 100% !important;
    background-color: $color-content-background-light !important;
  }
  div[data-cy="text"] {
    display: none !important;
  }
}

.actions {
  gap: $size-s;
  align-items: center;
}

.itemContainer {
  margin-left: $size-ml;
}