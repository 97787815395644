@import "../../styles/vars.scss";

.error-box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px;
  box-sizing: border-box;
  text-align: center;
  .title {
    margin: 10px 0;
    font-size: $fontSizeLg;
  }
  ul {
    list-style-type: disc;
  }
  .description {
    color: $color-grey-60;
  }
}
