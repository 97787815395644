@import "../../styles/vars.scss";

.container {
  .floatingForm {
    width: 300px;
    z-index: 1;
    .innerContainer {
      background-color: $color-brand-white;
      border: 1px solid $color-grey-30;
      border-radius: 3px;
      box-shadow: 0px $size-xs 10px $color-grey-30;
      .selectContainer {
        border: none;
        box-shadow: none;
      }
      .buttons {
        display: flex;
        flex-direction: row;
        padding: $size-m;
        .secondary {
          padding-right: $size-s;
          width: 100%;
        }
        .primary {
          padding-left: $size-s;
          width: 100%;
        }
        .button {
          width: 100%;
        }
      }
    }
  }
}
