@import "../../styles/vars.scss";
.dropdownTitle {
  font-weight: 400;
  margin-bottom: $size-xs;
  color: $color-grey-60;
  font-size: $font-size-s;
  .required {
    color: $color-status-red;
  }
}
.errorContainer {
  margin-top: $size-xs;
  color: $color-status-red;
  display: flex;
  flex-direction: row;
  .errorText {
    font-size: $font-size-xs;
    padding-left: $size-s;
  }
}
.dropdownContainer {
  .valueContainer {
    border: 1px solid $color-grey-30;
    border-radius: 3px;
    height: 50px;
    display: flex;
    align-items: center;
    span {
      padding: $size-xs $size-s;
    }
    .selectedItems {
      font-weight: bold;
      font-size: $font-size-s;
    }

    .placeholder {
      color: $color-grey-30;
      font-size: $font-size-s;
    }

    .valueIcon {
      margin-left: auto;
      padding-right: $size-s;
    }
  }

  .selectContainer {
    right: 0;
    position: absolute;
    width: 100%;
    display: flex;
  }
}
